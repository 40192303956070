<template>
   <v-bottom-navigation
      color="primary" fixed
    >
    
      <v-btn :to="{name: 'Logbuecher'}" v-if="getAktivesTertial === null" @click="auswahlLogbuch">
        <span>Wähle Logbuch</span>
  
        <v-icon>mdi-book</v-icon>
      </v-btn>

     <v-btn v-if="getAktivesTertial !== null" @click="ggfzurueck" :class="currentRouteName === 'StartTertial' ? 'v-btn--active' : '' ">
        <span>Tertial: {{getAktivesTertialContent.name}}</span>
  
        <v-icon>mdi-book</v-icon>
      </v-btn>


      <v-btn :to="{name: 'APTs'}" v-if="getAktivesTertial != null">
        <span>APTs</span>
  
          <icon-base width="22" height="22"  icon-name="TestIcon"><icon-test/></icon-base>

      </v-btn>
  
     
  
   <v-btn :to="{name: 'Gespraeche'}" v-if="getAktivesTertial != null">
        <span>Gespräche</span>
  
        <v-icon>mdi-account-supervisor</v-icon>
      </v-btn>

      

 
     
    </v-bottom-navigation>
</template>

<script>

import { mapGetters } from 'vuex';


  export default {
    name: 'AppBarBottom',
     computed: {
     currentRouteName() {
        return this.$route.name;
    },
     ...mapGetters([
        'getAktivesTertial', 'getAktivesTertialContent'
    ])
    },
    
    
    
    methods: {
    
    ggfzurueck() {
           if(this.currentRouteName == "StartTertial") {
                       this.$store.commit('setAktivesTertial',{ payload: null })
                       this.$router.push('/Logbuecher');

           }
           else {
           this.$router.push('/StartTertial');
           
           }
  
  },
  
  
      auswahlLogbuch () {
            this.$store.commit('setAktivesTertial',{ payload: null })
            
            
  
  }
    }
    
  }
</script>
