import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		light: true,
		themes: {
			light: {
				
				
				
				primary: '#004a92',
				secondary: '#b0bec5',
				gk:'#f5f4f5',
				hk:'#004a92',
				accent: '#575757',
				error: '#b71c1c',
				gkbg: '#E6E8C4'},
				
			
},
icons: {
    iconfont: 'faSvg',
  },
},
});
