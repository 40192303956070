import 'babel-polyfill';
import Vue from 'vue'
import App from './App.vue'
//import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import { config } from './config';

import VueMeta from 'vue-meta'
Vue.use(VueMeta)




import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {hideModules: { 'justifyRight': true,'justifyCenter': true,'justifyLeft': true, 'headings': true,'link': true,'code': true,'separator': true,}, maxHeight: '400px', forcePlainTextOnPaste: true , image: {uploadURL: config.$api_url_upload ,dropzoneOptions: {autoProcessQueue: false,acceptedFiles:'.png,.jpg,.gif,.bmp,.jpeg', dictDefaultMessage: 'Drop file here or click to upload!',
      addRemoveLinks: true,
      init: function () {
        this.on('success', function(file,response){
                store.commit('updateNewImageFilePath',{ value: JSON.stringify(response) });

        });
        }//init
        
        
        }//dropzone options
        
        }//image
        
        }//wysiwyg options
        ); // config is optional. more below
import VueSanitize from "vue-sanitize";


let defaults = VueSanitize.defaults;

defaults.allowedTags = [ 
   "blockquote", "dd", "div", "dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p", "pre",
  "ul", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn",
  "em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "s", "samp",
  "small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr", "caption",
  "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr" ];

defaults.allowedAttributes = {
  a: [  'name', 'target' ],
  // We don't currently allow img itself by default, but this
  // would make sense if we did. You could add srcset here,
  // and if you do the URL is checked for safety
    img: [ 'src', 'srcset', 'alt', 'title', 'width', 'height', 'loading' ]

},
// Lots of these won't come up by default because we don't allow them
defaults.selfClosing = [ 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta' ],

Vue.use(VueSanitize,defaults);



Vue.prototype.appConfig = config


import bcrypt from 'bcryptjs';
Vue.use(bcrypt)


import TestIcon from '@/components/SVGs/TestIcon.vue'
Vue.component('icon-test', TestIcon);
import Steps from '@/components/SVGs/Steps.vue'
Vue.component('steps-svg', Steps);
import IconBase from '@/components/SVGs/IconBase.vue'
Vue.component('icon-base', IconBase);




Vue.use(VueAxios, axios)

axios.defaults.baseURL = config.$api_axios_base_url;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';



Vue.use(Donut);





Vue.config.productionTip = false

new Vue({
	vuetify,
	router,
	store,
	render: function (h) { return h(App) },
}).$mount('#app')
