let config;

 {
 


 if (window.location.href.indexOf("pjlb-test.uke") > -1) {
 config = {
    $api_axios_base_url: "/",
    timeoutDuration: 30000,
    $api_url_auth: "/pj-auth.php",
    $api_url_arbeitsdaten: "/pj-arbeitsdaten.php",
    $api_url_inhalte: "/pj-inhalte.php",
    $api_url_upload: "/img_api.php",
    $img_url: "/",
    
  };
 }
 
 
 else if (window.location.href.indexOf("pjlogbuch.uke") > -1) {
 config = {
    $api_axios_base_url: "/",
    timeoutDuration: 30000,
    $api_url_auth: "/pj-auth.php",
    $api_url_arbeitsdaten: "/pj-arbeitsdaten.php",
    $api_url_inhalte: "/pj-inhalte.php",
    $api_url_upload: "/img_api.php",
    $img_url: "/",

    
  };
 }
 
 
 

 else if (window.location.href.indexOf("localhost:8080") > -1) {
 alert("Testversion LOKAL")
  config = {
    $api_axios_base_url: "http://localhost:31415",
    timeoutDuration: 30000,
    $api_url_auth: "http://localhost:31415/pj-auth.php",
    $api_url_arbeitsdaten: "http://localhost:31415/pj-arbeitsdaten.php",
    $api_url_inhalte: "http://localhost:31415/pj-inhalte.php",
    $api_url_upload: "http://localhost:31415/img_api.php",
    $img_url: "http://localhost:31415/",
  };
 }
 
else if (window.location.href.indexOf("localhost:31415") > -1) {
alert("Testversion Build. Daten Lokal")
  config = {
    $api_axios_base_url: "http://localhost:31415",
    timeoutDuration: 30000,
    $api_url_auth: "http://localhost:31415/pj-auth.php",
    $api_url_arbeitsdaten: "http://localhost:31415/pj-arbeitsdaten.php",
    $api_url_inhalte: "http://localhost:31415/pj-inhalte.php",
    $api_url_upload: "http://localhost:31415/img_api.php",
    $img_url: "/",
  };
 }

 else {
 alert("Aufruf der Seite außerhalb der freigegebenen Umgebung. Bitte Info mit Screenshot an d.baumann@uke.de falls Fehler unerwartet auftritt.");
 }
 
 }

export { config }