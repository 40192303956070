<template>


<div>



<v-app-bar
        color="#1a4a8d"
        dark
        shrink-on-scroll
        prominent
        :src="(getAktivesTertialContent === null || !getTertialeNamen.includes(getAktivesTertialContent.name)) ? 'assets/UKE.jpg' : getAktivesTertialContent.src "
        fade-img-on-scroll
        scroll-target="#scrolling-techniques-3"
      >
        <template v-slot:img="{ props }">
          <v-img
            v-bind="props"
            gradient="to top right, rgba(26, 74, 141, 1.000), rgba(26, 74, 141, .400)"
          ></v-img>
        </template>
  
       <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title> <v-row>
 <v-col
            id="kurzerAbstand"
            cols="12"
            style="min-width:90vw"
          >
PJ Logbuch    {{(getAktivesTertialContent === null || !getTertialeNamen.includes(getAktivesTertialContent.name)) ? '' : getAktivesTertialContent.name}}
         
 </v-col>
            </v-row>
            <v-row>
           
           
            <v-col
            id="kurzerAbstand"
            cols="12"
            style="min-width:90vw"
          >
          
           
           
          <span v-for="(breadcrumb, xloop) in getbreadcrumbItems" :key="xloop">
          <span v-if="breadcrumb != undefined">

          <span v-if="xloop > 0" class="white--text body-2 text pr-2">&gt;</span>

          <span v-if="xloop == getbreadcrumbItems.length-1" class="white--text body-2 text pr-2">{{breadcrumb.breadcrumbtitle}}</span>
          <span v-else class="white--text body-2 text pr-2" @click="routebreadcrumb(breadcrumb)">{{breadcrumb.breadcrumbtitle}}</span>
          </span>
          </span> 
          <!-- 
  <v-breadcrumbs
                    :items="getbreadcrumbItems"
                    divider=">"
                  >
                    <template v-slot:item="props">
                      <router-link :to="props.item.href" v-if="!props.item.disabled">
                        <v-breadcrumbs-item
                          :class="[props.item.disabled && 'disabled']"
                        >
                          {{ props.item.text }}
                        </v-breadcrumbs-item>
                      </router-link>
                      <v-breadcrumbs-item
                        :class="[props.item.disabled && 'disabled']"
                        v-else
                      >
                        {{ props.item.text }}
                      </v-breadcrumbs-item>
                    </template>
                  </v-breadcrumbs>
 -->
            
          </v-col>
  
       </v-row>
       
       
      </v-toolbar-title>
        
  
      </v-app-bar>


<v-navigation-drawer
        v-model="drawer"
        absolute
        left
        temporary
      >
       <template v-slot:prepend>
          <v-list-item two-line v-if="getAuthentifikation">
            <v-list-item-avatar>
              <v-icon>{{ getNutzer.rolle == 0 ? 'mdi-school' : 'mdi-account-supervisor'}} </v-icon>
            </v-list-item-avatar>
  
            <v-list-item-content>
              <v-list-item-title>{{ getNutzer.vorname }} {{ getNutzer.nachname }}</v-list-item-title>
              <v-list-item-subtitle>{{ getNutzer.rolle == 0 ? 'PJler*in' : 'PJ Betreuer*in' }}</v-list-item-subtitle>
              <v-list-item-subtitle>Angemeldet</v-list-item-subtitle>

            </v-list-item-content>
          </v-list-item>
        </template>
  
        <v-divider></v-divider>
  
        <v-list dense>

          <v-list-item @click="redirectHome">
            <v-list-item-icon>
              <v-icon>mdi-application</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Applikation</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
           <v-list-item v-if="getAuthentifikation" @click="$router.push('Profil')">
            <v-list-item-icon>
              <v-icon>mdi-badge-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Benutzerprofil</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          
  <v-list-item v-if="getAuthentifikation" @click="$router.push('Unterschriftenliste')">
            <v-list-item-icon>
              <v-icon>mdi-pencil-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Unterschriftenliste</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
          
     <v-list-item v-if="getAuthentifikation && getNutzer.rolle > 99" @click="$router.push('AdminDashboard')">
            <v-list-item-icon>
              <v-icon>mdi-star</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Admin Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
          
  <v-list-item v-if="getAuthentifikation && getNutzer.rechtfreigabeneuenutzer == 1" @click="$router.push('Benutzerverwaltung')">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Benutzerverwaltung</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

         <!-- 
 <v-list-item v-if="getAuthentifikation">
            <v-list-item-icon>
              <v-icon>mdi-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Einstellungen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
 -->
          
           <v-list-item @click="$router.push('Impressum')">
            <v-list-item-icon>
              <v-icon>mdi-card-account-mail</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Impressum</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
           <v-list-item v-if="getAuthentifikation" @click="$router.push('Quellen')">
            <v-list-item-icon>
              <v-icon>mdi-comment-text-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Quellen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          

          <v-list-item @click="logout" v-if="getAuthentifikation">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Abmelden</v-list-item-title>
            </v-list-item-content>
          </v-list-item>



        </v-list>
      </v-navigation-drawer>



    
  
      



</div>






 
</template>

<script>


  import { mapGetters } from 'vuex';

    

  export default {

    name: 'AppBarTop',
    data: () => ({  
      drawer: false,
      group: null,
     

      }),
    watch: {
    group () {
      this.drawer = false
    },  },
     methods: {
      
    async logout() {
      
        this.$store.commit('setAuthentifikation', { payload: false});
        this.$store.commit('setNutzer', { payload: null});
        this.drawer = false;
        this.$store.commit('setbreadcrumbItems', { payload:{}});
            
        this.$router.go('/');


        
    },
    
    redirectHome () {
    this.$router.push('/');
    this.$store.commit('setbreadcrumbItems', { payload:{}});

    }, 
    routebreadcrumb (payload) {
            //lert("yeah"+JSON.stringify(payload));
            //this.$store.commit('setAktiveEPA',{ value: value })
            if (!(typeof payload === 'undefined')) {
            //alert(JSON.stringify(payload));
            this.$router.push('/'+payload.route);
            }
            //this.$store.commit('setbreadcrumbItems', { payload:{ route: 'EPA',vars: {epa_id: this.nummer, titel: this.titel }}});
        },
        
        },
      computed: { ...mapGetters([
       'getBarTitle', 'getbreadcrumbItems', 'getAktivesTertial', 'getAktivesTertialContent', 'getAuthentifikation', 'getNutzer', 'getTertialeNamen']),
       
       currentRouteName() {
        return this.$route.name;
    },
   
       }

  }
</script>




<style>
.v-breadcrumbs {padding:0 0 !important; }
#kurzerAbstand {padding:12px 0px 4px 0px !important; }
a.v-breadcrumbs__item {
    color: white !important
  }
</style>