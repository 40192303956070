<template>
<v-container class="LoginContainer">
<v-row justify="center" class="pt-12" >
      <v-col :cols="noCols">  
<v-card flat>
  <v-alert v-if="showErfolgreicheRegistrierung" dense text type="success" >
        Die Registrierung wurde <strong>erfolgreich</strong> abgeschlossen. Nutzen sie ihre Login-Daten um das Logbuch zu Öffnen.
      </v-alert>
      

        <v-img v-if="mode=='Login'" height="20%" width="20%"  class="mx-auto" tile contain src="@/images/logo.png"></v-img>


      
        <v-alert v-if="showLoginAbbruch" dense text type="warning" >
        Der Login konnte <strong>nicht</strong> durchgeführt werden. Überprüfen Sie die E-Mail und Passworteingabe.
      </v-alert>
      <v-sheet v-if="mode=='Unterschreiben'" color="primary" height=55px class="text-h5 font-weight-light text-center white--text" style="padding-top: 10px">Logbucheintrag abschliessen <v-btn icon color="white"  @click="CloseUnterschrift()" ><v-icon>mdi-close</v-icon></v-btn></v-sheet>

       <v-alert v-if="mode=='Unterschreiben'" dense text type="info" >
       Bitte Unterschreiben Sie die Dokumentation im Logbuch durch Passworteingabe der Unterschreibenden Person. 
      </v-alert>
      
      
                <v-tabs show-arrows background-color="transparent" color="primary" icons-and-text grow  v-model="tab">
                    <v-tabs-slider color="primary"></v-tabs-slider>
                    <v-tab>
                        <v-icon large>mdi-account</v-icon>
                        <div class="caption py-1" >{{mode=='Unterschreiben'? 'Unterschreiben' : 'Login'}}</div>
                    </v-tab>
                          <v-tab v-if="mode=='Login'"  >
                        <v-icon large >mdi-account-plus</v-icon>
                        <div class="caption py-1" color="green">Registrieren</div>
                    </v-tab>
                    
                    
                </v-tabs>
                
                <v-tabs-items v-model="tab">

                <v-tab-item color="success">
                        <v-card class="px-4 pb-12" flat>
                            <v-card-text>
                                <v-form ref="login" class="login" v-model="valid" lazy @submit.prevent="login">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field v-if="mode=='Login'" v-model="loginEmail" label="E-mail" required :rules="emailRules"></v-text-field>
                                            <v-text-field v-if="mode=='Unterschreiben'" readonly label="E-mail" :value="pendant.email"></v-text-field>

                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="loginPasswort" :append-icon="show1?'eye':'eye-off'" :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" label="Passwort" hint="> mehr als 6 Zeichen" @click:append="show1 = !show1"></v-text-field>
                                        </v-col>
                                        <v-col class="d-flex" cols="12" sm="6" xsm="12">
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col class="d-flex" cols="12" sm="6" xsm="12" align-end>
                                            <v-btn x-large block :disabled="!valid" color="success" @click="loginUser()" class="text-subtitle-2 font-weight-light text-center small">{{mode=='Unterschreiben'? 'Unterschreiben' : 'Login'}}  </v-btn>
                                        </v-col>
                                    </v-row>
                                    
                                    
                                     <v-row>
                                        
                                        <v-spacer></v-spacer>
                                        <v-col class="d-flex" cols="12" sm="6" xsm="12" align-end>
                                            <v-btn x-large plain color="gray" @click="passwortvergessen()" class="text-subtitle-2 font-weight-light text-center small">Passwort vergessen </v-btn>
                                        </v-col>
                                         <v-spacer></v-spacer>
                                    </v-row>
                                    
                                    
                                </v-form>
                            </v-card-text>
                        </v-card>
         
         

        
        
        <div class="text-center pt-10 pb-4" v-if="mode=='Login'">
    <v-btn :to="{name: 'Impressum'}" elevation-0>
        <span>Impressum</span>
              </v-btn>
     
    </div>
    
    
    
         
        

      
      
                    </v-tab-item>
                    
                      <v-tab-item>
                        <v-card class="px-4" flat v-if="mode=='Unterschreiben'">
                            <v-card-text>
                                <v-form ref="later" class="later" v-model="valid">
                                    <v-row>
                                              <p class="text-break text-subtitle-2 font-weight-light text-center">Zum späteren Unterschreiben an den {{getNutzer.rolle > 1 ? 'PJler*in': 'PJ Betreuer*in'}} schicken. (Z.b. Bei Unterbrechung oder Zeitdruck) </p>

                                    <v-col cols="12">
                                           
                                           
                                           
             <v-autocomplete
                v-model="selectedpendant"
                :items="getUnterschriftPendantListe"
                filled
                chips
                color="blue-grey lighten-2"
                label="Wähle "
                item-text="item"
                
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    
                     {{ data.item.vorname }} {{ data.item.nachname }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  
                  <template >
                    
                    <v-list-item-content>
                      <v-list-item-title >  {{ data.item.vorname }} {{ data.item.nachname }}</v-list-item-title>
                      <v-list-item-subtitle> {{ data.item.wbjahr }}. Weiterbildungsjahr</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              
              
                                        </v-col>
                                         <v-spacer></v-spacer>
                                        <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                                            <v-btn x-large block :disabled="!valid" color="success" @click="addToBucketlist()" class="text-subtitle-2 font-weight-light text-center small"> Senden, und später abschliessen </v-btn>
                                        </v-col>
                                        
                                        
                                       
                                          
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>
                        
                         <v-card class="px-4" flat v-if="mode=='Login'">
                            <v-card-text>
                                <v-form ref="register" class="register" v-model="valid" @submit.prevent="login">
                                    <v-row>
                                    
                                    <v-col cols="12">
                                            <v-text-field v-model="registerEmail" label="Email" :rules="emailRules" required></v-text-field>
                                        </v-col>
                                        
                                        <v-col cols="12">
                                            <v-text-field v-model="registerVorname" label="Vorname" :hint="'Titel ggf. davor'" required></v-text-field>
                                        </v-col>
                                        
                                         <v-col cols="12">
                                            <v-text-field v-model="registerNachname" label="Nachname" required></v-text-field>
                                        </v-col>
                                       
                                         <v-col cols="12">
                                         <v-select v-model="registerRolle" :hint="'Rolle'" :items="rolle" item-text="bezeichnung" item-value="id"  label="Auswählen" persistent-hint  return-object single-line></v-select>
                                        </v-col>
                                         
                                         <!-- 
                                         <v-col cols="12">
                                            <v-select v-model="registerLehrkrankenhaus" :items="registerLehrkrankenhausSelection" hint="Lehrort Auswählen..." label="Lehrkrankenhaus/Praxis" persistent-hint required></v-select>
                                        </v-col>
                                        -->
                                          <v-col cols="12" v-if="registerRolle!=null&&registerRolle.id==0">
                                            
                                            <v-select  v-model="registerRotation" :items="getTertialeNamen" :menu-props="{ maxHeight: '400' }" label="Auswahl aktuelle Rotation" hint="Rotation Auswählen..." persistent-hint ></v-select>
                                        </v-col>
                                        
                                         
                                             
                                         <v-col cols="12">
                                            <v-text-field v-model="registerPasswort" :append-icon="show1?'eye':'eye-off'" :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Passwort" counter @click:append="show1 = !show1"></v-text-field>
                                        </v-col>
                                         <v-col cols="12">
                                            <v-text-field v-model="registerPasswortWdh" :append-icon="show1?'eye':'eye-off'" :rules="[(registerPasswort === registerPasswortWdh) || 'Passwörter nicht gleich']" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Passwort" counter @click:append="show1 = !show1"></v-text-field>
                                        </v-col>
                                        <v-col class="d-flex" cols="12" sm="6" xsm="12">
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                                            <v-btn x-large block :disabled="!valid" color="grey" @click="registerUser" class="text-subtitle-2 font-weight-light text-center"> Registrieren </v-btn>
                                            </v-col>
                                          
                                    </v-row>
                                </v-form>
                                
                                
                                
                                

      
      
      
          
          
                            </v-card-text>
                        </v-card>
                        
                        
                     <!-- 
    <v-card class="px-4" flat v-if="(geteditMode == 0) && (mode=='Login')">
                            <v-card-text>
                               <v-alert  dense text type="info" >
       Zum Registrieren für das Weiterbildungslogbuch bitte Kontakt zu Parisa Moll-Khosrawi aufnehmen.
      </v-alert>
                                
                            </v-card-text>
                        </v-card>
                        
 -->
                        
                    </v-tab-item>
                    
                    
                    </v-tabs-items>
         
         
                                         

      
</v-card>


</v-col>
</v-row>
</v-container>
</template>

<script>


  import { mapGetters } from 'vuex';
  import axios from 'axios'
  import bcrypt from 'bcryptjs';


    

  export default {
  

    name: 'Login',
    data: () => ({  
    
    showErfolgreicheRegistrierung: false,
    showLoginAbbruch: false,

    registerEmail: '',
    registerVorname: '',
    registerNachname: '',
    registerRolle: '',
    registerLehrkrankenhaus: 'UKE',
    registerLehrkrankenhausSelection: ["anderer Lehrort"],
    registerPasswort: '',
    registerPasswortWdh: '',
    registerRotation: '',
    registerWbjahr: '1',
    laterEmail : "",
    loginEmail: "",
    loginPasswort: "",
    

	selectedpendant: null,
	tab: 0,

    rolle: [{bezeichnung: 'PJler*in', id: 0},{bezeichnung: 'PJ Betreuer*in', id: 1}],
    selectwbjahr: ["1","2","3","4","5"],

    
         dialog: true,
         valid: false,
         
         show1: false,
         rules: {
         required: value => !!value || "Benötigt.",
         min: v => (v && v.length >= 6) || "Mindestens 6 Zeichen",
         },
         emailRules: [
  v => !!v || 'E-mail muss angeben sein',
  v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail nicht gültig',
 ],
     

      }),
       
           props: ['mode', 'pendant'],

   mounted() {
   let vthis = this; //in Verschachtelten Funktionen vthis statt this! https://www.techalyst.com/posts/vuejs-this-is-undefined-error-in-javascript-array-filter-and-functions
       

        vthis.axios.post(vthis.appConfig.$api_url_auth, {
         
            funktion: "getLehrorte",
            
          },{
  headers: {
    'Content-Type': 'application/json'
  }
        })
        .then(function (response) {
            vthis.registerLehrkrankenhausSelection = vthis.registerLehrkrankenhausSelection.concat(response.data.lehrorte.filter(e => e !== 'Test' && e !== 'Admin' && e !== 'Editor'));
        })
        .catch(function (error) {
           console.log(error);
        });
        
   },
   
   
     methods: {
     
     
     
   
 passwortvergessen() {
 
 alert("Im Rahmen der Nutzung der Weiterbildungslogbuches Anästhesiologie kann das Passwort nur durch die Administratoren neu gesetzt werden. Bitte Info mit Bitte um Passwortzurücksetzung an Parisa Moll-Khosrawi");
 },
             
     
    
        
      
       
        
        
          
    
    loginUser() {
       let vthis = this; //in Verschachtelten Funktionen vthis statt this! https://www.techalyst.com/posts/vuejs-this-is-undefined-error-in-javascript-array-filter-and-functions
		var emailauth = vthis.loginEmail;
		if (this.mode == "Unterschreiben") {
		emailauth = this.pendant.email;
		}
		
        axios.post(vthis.appConfig.$api_url_auth, {
             funktion: "login",
             login: emailauth,
        },{
  headers: {
    'Content-Type': 'application/json'
  }
})
        .then(function (response) {

        var pwhash = response.data.pwhash;
        if (pwhash != undefined) {
        if(bcrypt.compareSync(vthis.loginPasswort, pwhash)) {
         if (vthis.mode == "Login") {
         
         if (response.data.freigegeben == 0) {
         alert("Account noch nicht Freigegeben. Bitte um Freigabe durch Parisa Moll-Mhosrawi. Sprechen sie ihren PJ Betreuer an für Rückfragen.");
         }
         else {
         
    
         if(vthis.getTertialeNamen.includes(response.data.rotation)) {
        vthis.$router.push('/StartTertial').catch(()=>{});
        }
        else
        {
        vthis.$router.push('/Logbuecher').catch(()=>{});
        }
        vthis.$store.commit('setAuthentifikation', { payload: true});
        vthis.$store.commit('setNutzer', { payload: response.data});
        window.scrollTo({ top: 0, behavior: "smooth" });

        vthis.axios.post(vthis.appConfig.$api_url_arbeitsdaten, {
             funktion: "getPruefungen",
             userid: vthis.getNutzer.id
           }, {
  headers: {
    'Content-Type': 'application/json'
  }
        })
        .then(function (response) {
        var stateObject = response.data;
        
        
        //vthis.$store.commit('setvomNutzerimLogbuchGespeichertePruefungen', { payload: stateObject.pruefungen });
        vthis.$store.dispatch('UpdateUnterschriftPendantListeFromAPI');
        vthis.$store.dispatch('UpdatePruefungenFromAPI');
        vthis.$store.dispatch('UpdateGespraecheFromAPI');
        vthis.$store.dispatch('returnAusstehendeUnterschrift');





        
        })
        .catch(function (error) {
           console.log(error);
        });
        }
        } 
        else  if (vthis.mode == "Unterschreiben") {
        vthis.$store.commit('setausbilderUnterschrift', { payload: response.data });
        vthis.$store.commit('setpopoverausbilderUnterschrift',{ payload: false });
        vthis.$emit("callMethodInParent");

        }
        }
        
         else {
          vthis.showLoginAbbruch = 1;
          }
        
}

       
        })
        .catch(function (error) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        });
        },
             
     registerUser() {
       let vthis = this; //in Verschachtelten Funktionen vthis statt this! https://www.techalyst.com/posts/vuejs-this-is-undefined-error-in-javascript-array-filter-and-functions

        vthis.axios.post(vthis.appConfig.$api_url_auth, {
             funktion: "add",
             vorname: vthis.registerVorname,
             nachname: vthis.registerNachname,
             email: vthis.registerEmail,
             rolle: vthis.registerRolle.id,
             lehrort: vthis.registerLehrkrankenhaus,
             rotation: vthis.registerRotation,
             pwhash: bcrypt.hashSync(vthis.registerPasswort,3),
         },{
  headers: {
    'Content-Type': 'application/json'
  }
        })
        .then(function (response) {
        var stateObject = response.data;
        if (JSON.stringify(stateObject) == '1') {
        vthis.tab = 0;
        vthis.showErfolgreicheRegistrierung = 1;
        }
        else {
        alert ("Es gab ein Problem. Bitte Informieren sie den Administrator.");
                console.log(JSON.stringify(stateObject));

        }
        //commit('setAPTs', { payload: stateObject.apts });
       // commit('setGKs', { payload: stateObject.gks });
        //commit('setHKs', { payload: stateObject.hks });
        //commit('setSMPs', { payload: stateObject.smps });
        //commit('sethksInhalte', { payload: stateObject.hksInhalte });

        })
        .catch(function (error) {
           console.log(error);
        });
        },
        
        
        
        encryptPassword(password)     {      
          const salt = bcrypt.genSaltSync(10)
          return bcrypt.hashSync(password, salt)
        },
        
         CloseUnterschrift() {
     this.$store.commit('setpopoverausbilderUnterschrift',{ payload: false });

  },
  
        
        
        
  
        
        
    
        
        },
     
       
     
         

        
        
        
    computed: { ...mapGetters([
    'getAuthentifikation', 'gettertiale', 'getTertialeNamen', 'getNutzer', 'geteditMode', 'getUnterschriftPendantListe'
       ]),
       
       
       noCols () {
       
       if (this.mode == "Unterscheiben") {
       
       switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 12
          case 'sm': return 10
          case 'md': return 6
          case 'lg': return 6
          case 'xl': return 6
        }
        }
        
        else {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 12
          case 'sm': return 10
          case 'md': return 6
          case 'lg': return 6
          case 'xl': return 6
        }
        }
        return 12;
      },
      
    
          
       
    
     
       
       currentRouteName() {
        return this.$route.name;
    },
       
       }

  }
</script>


